@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&family=Reggae+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600&family=Roboto+Mono:wght@200;300&family=Ubuntu+Mono&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#stars1 {
  animation: stars1 1s linear infinite;
}

@keyframes stars1 {
  50% {
    opacity: 0;
  }
}

#stars2 {
  animation: stars1 1.3s linear infinite;
}

@keyframes stars2 {
  50% {
    opacity: 0;
  }
}
