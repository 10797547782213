* {
  box-sizing: border-box;
}

:root {
  --heights: 55vh;
  --widths: 70%;
}

.slider-container {
  height: var(--heights);
  width: 70%;
  position: relative;
  margin: auto;
  overflow: hidden;
  /* background-color: red; */
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

/* .slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  background-color: yellow;
} */

.slide-image {
  width: 100%;
  height: 100%;
  /* margin: 0 70px; */
  position: absolute;
  object-fit: scale-down;
  /* background-color: aqua; */
}

/* .slide-title {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: center;
  top: 50%;
  z-index: 9;
} */

.prev, .next {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 30px;
  font-weight: bold;
  border-radius: 0 5px 5px 0;
  /* background-color: pink; */
}

.prev:hover, .next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0 0 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 200;
  /* background-color: aquamarine; */
}

.dot {
  cursor: pointer;
  height: 0.7rem;
  width: 0.7rem;
  margin: 0 6px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.active-dot, .dot:hover {
  background-color:#FACA67;
}