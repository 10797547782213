@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&family=Reggae+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600&family=Roboto+Mono:wght@200;300&family=Ubuntu+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#stars1 {
  animation: stars1 1s linear infinite;
}

@keyframes stars1 {
  50% {
    opacity: 0;
  }
}

#stars2 {
  animation: stars1 1.3s linear infinite;
}

@keyframes stars2 {
  50% {
    opacity: 0;
  }
}

* {
  box-sizing: border-box;
}

:root {
  --heights: 55vh;
  --widths: 70%;
}

.slider-container {
  height: 55vh;
  height: var(--heights);
  width: 70%;
  position: relative;
  margin: auto;
  overflow: hidden;
  /* background-color: red; */
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

/* .slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  background-color: yellow;
} */

.slide-image {
  width: 100%;
  height: 100%;
  /* margin: 0 70px; */
  position: absolute;
  object-fit: scale-down;
  /* background-color: aqua; */
}

/* .slide-title {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: center;
  top: 50%;
  z-index: 9;
} */

.prev, .next {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 30px;
  font-weight: bold;
  border-radius: 0 5px 5px 0;
  /* background-color: pink; */
}

.prev:hover, .next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0 0 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 200;
  /* background-color: aquamarine; */
}

.dot {
  cursor: pointer;
  height: 0.7rem;
  width: 0.7rem;
  margin: 0 6px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.active-dot, .dot:hover {
  background-color:#FACA67;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

